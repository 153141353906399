import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_MAPAPARCEIRO_PORT}${process.env.VUE_APP_API_PATH}`;
//const urlBase = `${api}/pessoa`;

export default {
    ConsultaCnpjReceitaFederal(cnpj){
        return axiosJwt.get(
            `${api}/mapaparceiros/pessoa/consultarcnpjreceitafederal/${cnpj}`
        );
    },

    ConsultaCep(cep){
        return axiosJwt.get(
            `${api}/mapaparceiros/pessoa/consultarcep/${cep}`
        );
    },

    ConsultaCepInterlocutor(cep){
        return axiosJwt.get(
            `${api}/mapaparceiros/interlocutor/consultarcepinterlocutor/${cep}`
        );
    },

    SelectAllParceiros(filtro) {
        return axiosJwt.get(
            `${api}/mapaparceiros/pessoa/selectallparceiros?cnpj=${filtro.cnpj}&razaosocial=${filtro.razaoSocial}&situacao=${filtro.situacao}`
        );
    },

    ObterCamposTextosFixos() {
        return axiosJwt.get(
            `${api}/mapaparceiros/parceria/obterCamposTextosFixos`
        );
    },


    SelectByIdPessoa(id) {
        return axiosJwt.get(
            `${api}/mapaparceiros/pessoa/selectbyid/${id}`
        );
    },

    SelectAllAreaAtuacao() {
        return axiosJwt.get(
            `${api}/mapaparceiros/areaatuacao/selectall`
        );
    },

    ObterTodasAcoes() {
        return axiosJwt.get(
            `${api}/mapaparceiros/parceria/acao/obtertodasacoes`
        );
    },

    ExcluirAcaoParceria(id) {
        return axiosJwt.delete(
            `${api}/mapaparceiros/parceria/acao/remover/${id}`
        );
    },

    InserirOuAlterarAcaoParceria(id, obj) {
        if(id != 0){
            return axiosJwt.post(`${api}/mapaparceiros/parceria/acao/alterar/${id}`, obj);
        }
        return axiosJwt.post(`${api}/mapaparceiros/parceria/acao/inserir`, obj);
    },

    InserirLicoesAprendidasParceria(obj) {
        return axiosJwt.post(
            `${api}/mapaparceiros/parceria/inserir/licoesaprendidas`, obj
        );
    },

    SalvarComentarioStatusParceria(obj) {
        return axiosJwt.post(
            `${api}/mapaparceiros/parceria/comentariostatus`, obj
        );
    },

    SelectAllFuncionario(parceriaId, parceriaAditivoId) {
        return axiosJwt.get(
            `${api}/mapaparceiros/funcionarios?parceriaId=${parceriaId ? parceriaId : ''}&parceriaAditivoId=${parceriaAditivoId ? parceriaAditivoId : ''}`,
        );
    },

    SelectAllSetor() {
        return axiosJwt.get(
            `${api}/mapaparceiros/setor/selectall`
        );
    },

    SelectAllNaturezaJuridica() {
        return axiosJwt.get(
            `${api}/mapaparceiros/naturezajuridica/selectall`
        );
    },

    InserirParceiro(pessoaDto) {
        return axiosJwt.post(`${api}/mapaparceiros/pessoa/inserirparceiro`, pessoaDto);
    },
    AlterarPessoa(obj) {
        return axiosJwt.post(`${api}/mapaparceiros/pessoa/alterar`, obj);
    },
    DeletarPessoa(id) {
        return axiosJwt.get(`${api}/mapaparceiros/pessoa/deletar/${id}`);
    },

    InserirPessoaAreaAtuacao(obj) {
        return axiosJwt.post(`${api}/mapaparceiros/pessoa/inserirpessoaareaatuacao`, obj);
    },
    DeletarPessoaAreaAtuacao(obj) {
        return axiosJwt.post(`${api}/mapaparceiros/pessoa/deletarpessoaareaatuacao`, obj);
    },

    InserirPessoaMidia(obj) {
        return axiosJwt.post(`${api}/mapaparceiros/pessoa/inserirpessoamidia`, obj);
    },
    DeletarPessoaMidia(obj) {
        return axiosJwt.post(`${api}/mapaparceiros/pessoa/deletarpessoamidia`, obj);
    },

    InserirInterlocutoresParceiros(obj) {
        return axiosJwt.post(`${api}/mapaparceiros/pessoa/inseririnterlocutoresparceiros`, obj);
    },
    DeletarInterlocutoresParceiros(obj) {
        return axiosJwt.post(`${api}/mapaparceiros/pessoa/deletarinterlocutoresparceiros`, obj);
    },

    SelectAllParcerias(filtro) {
        return axiosJwt.get(
            `${api}/mapaparceiros/parceria/selectall?dataExecussaoInicial=${filtro.dataExecussaoInicial}&dataInicioParceria=${filtro.dataInicioParceria
                }&dataExecussaoFinal=${filtro.dataExecussaoFinal}&unidadeRegionalId=${filtro.unidadeRegionalId
                }&dataFimParceria=${filtro.dataFimParceria}&parceiroId=${filtro.parceiroId}&evento=${filtro.evento
                }&numero=${filtro.numeroAc}&status=${filtro.status}&tags=${filtro.tags}&valorTotalAcordoAte=${filtro.valorTotalAcordoAte
                }&valorTotalAcordoDe=${filtro.valorTotalAcordoDe}&contraPartidaAte=${filtro.contraPartidaAte}&contraPartidaDe=${filtro.contraPartidaDe
                }&aporteSebraeAte=${filtro.aporteSebraeAte}&aporteSebraeDe=${filtro.aporteSebraeDe}&gerenteanalistaid=${filtro.gerenteAnalistaId}&filtroFinanceiro=${filtro.filtroFinanceiro
                }&eixo=${filtro.eixo}&subEixo=${filtro.subEixo}`);
    },

    SelectAllParceriasByIdParceiro(id) {
        return axiosJwt.get(
            `${api}/mapaparceiros/parceria/selectallbyidparceiro/${id}`
        );
    },

    SelectByIdParcerias(id) {
        return axiosJwt.get(
            `${api}/mapaparceiros/parceria/selectbyid/${id}`
        );
    },

    SelectByIdParceriasReplace(id) {
        return axiosJwt.get(
            `${api}/mapaparceiros/parceria/selectbyidreplace/${id}`
        );
    },

    InserirInterlocutoresParceria(obj){
        return axiosJwt.post(`${api}/mapaparceiros/parceria/inseririnterlocutor`, obj);
    },
    DeletarInterlocutoresParceria(obj){
        return axiosJwt.post(`${api}/mapaparceiros/parceria/deletarinterlocutor`, obj);
    },

    InserirUnidadesRegionaisParceria(obj){
        return axiosJwt.post(`${api}/mapaparceiros/parceria/inserirunidaderegional`, obj);
    },
    DeletarUnidadesRegionaisParceria(obj){
        return axiosJwt.post(`${api}/mapaparceiros/parceria/deletarunidaderegional`, obj);
    },
    SelectAllUnidadesRegionais() {
        return axiosJwt.get(
            `${api}/mapaparceiros/unidadesregionais/selectall`
        );
    },

    InserirDemaisEnvolvidos(obj){
        return axiosJwt.post(`${api}/mapaparceiros/parceria/associarfuncionarioparceria`, obj);
    },
    DeletarDemaisEnvolvidos(obj){
        return axiosJwt.post(`${api}/mapaparceiros/parceria/desassociarfuncionarioparceria`, obj);
    },

    InserirOuAtualizarParceria(obj) {
        if (obj.id > 0)
            return axiosJwt.post(`${api}/mapaparceiros/parceria/alterar`, obj);
        return axiosJwt.post(`${api}/mapaparceiros/parceria/inserir`, obj);
    },

    InserirOuAtualizarParceriaDadosGerais(obj) {
        if (obj.id > 0)
            return axiosJwt.post(`${api}/mapaparceiros/parceria/${obj.id}/alterar/dadosgerais`, obj);
        return axiosJwt.post(`${api}/mapaparceiros/parceria/inserir/dadosgerais`, obj);
    },

    SelectAllModalidadesApoio() {
        return axiosJwt.get(
            `${api}/mapaparceiros/modalidadeapoio/selectall`
        );
    },

    SelectAllInterlocutores(filtro) {
        return axiosJwt.get(
            `${api}/mapaparceiros/interlocutor/selectall?cpf=${filtro.cpf}&nomeInterlocutor=${filtro.nomeInterlocutor}`
        );
    },

    SelectByIdInterlocutores(id) {
        return axiosJwt.get(
            `${api}/mapaparceiros/interlocutor/selectbyid/${id}`
        );
    },
    InserirInterlocutor(obj){
        return axiosJwt.post(`${api}/mapaparceiros/interlocutor/inserir`, obj);
    },
    AlterarInterlocutor(obj){
        return axiosJwt.post(`${api}/mapaparceiros/interlocutor/alterar`, obj);
    },
    DeletarInterlocutor(id){
        return axiosJwt.get(`${api}/mapaparceiros/interlocutor/deletar/${id}`);
    },

    InserirOuAlterarParcelaParceria(id, obj) {
        if (id) {
            return axiosJwt.post(`${api}/mapaparceiros/parceria/atualizarparcela/${id}`, obj);
        }
        return axiosJwt.post(`${api}/mapaparceiros/parceria/inserirparcelas`, obj);
    },
    DeletarParcelasParceria(obj){
        return axiosJwt.post(`${api}/mapaparceiros/parceria/deletarparcelas`, obj);
    },

    DeletarParceria(id){
        return axiosJwt.get(`${api}/mapaparceiros/parceria/deletar/${id}`);
    },

    ExportarPdf(id) {
        return axiosJwt.get(`${api}/mapaparceiros/parceria/exportarpdf/${id}`, { responseType: 'blob' })
            .then(response => {
                return response.data
            })
    },

    ExportarAcoesDaParceriaEmExcel(id) {
        return axiosJwt.get(`${api}/mapaparceiros/parceria/${id}/acao/exportar/excel`, {responseType: 'blob'});
    },


    ObterMunicipios() {
        return axiosJwt.get(
            `${api}/mapaparceiros/parceria/obtermunicipios`
        );
    },

    InserirParceriaAditivo(obj){
        return axiosJwt.post(`${api}/mapaparceiros/parceria/inseriraditivo`, obj);
    },

    AlterarParceriaAditivo(obj){
        return axiosJwt.post(`${api}/mapaparceiros/parceria/alteraraditivo`, obj);
    },

    ObterParceriaAditivoPorId (id){
        return axiosJwt.post(`${api}/mapaparceiros/parceria/aditivo/${id}`);
    },

    InserirParceriaAditivoFuncionario(obj){
        return axiosJwt.post(`${api}/mapaparceiros/parceria/aditivo/${obj.parceriaAditivoId}/associarfuncionario`, obj);
    },

    DesassociarParceriaAditivoFuncionario(obj){
        return axiosJwt.post(`${api}/mapaparceiros/parceria/aditivo/${obj.parceriaAditivoId}/desassociarfuncionario`, obj);
    },

    InserirOuAtualizarParceriaAditivoParcelas(id, obj) {
        if (id) {
            return axiosJwt.post(`${api}/mapaparceiros/parceria/aditivo/alterarparcela/${id}`, obj);
        }
        return axiosJwt.post(`${api}/mapaparceiros/parceria/aditivo/${obj.parceriaAditivoId}/inserirparcela`, obj);
    },
    DeletarParceriaAditivoParcelas(obj){
        return axiosJwt.post(`${api}/mapaparceiros/parceria/aditivo/${obj.ParceriaAditivoId}/removerparcela`, obj);
    },

    InserirParceriaAditivoAcao(obj) {
        return axiosJwt.post(
            `${api}/mapaparceiros/parceria/aditivo/${obj.ParceriaAditivoId}/inseriracao`, obj
        );
    },

    RemoverParceriaAditivoAcao(obj) {
        return axiosJwt.post(
            `${api}/mapaparceiros/parceria/aditivo/${obj.ParceriaAditivoId}/removeracao`, obj
        );
    },

    ParceriaAditivoAtivar(obj) {
        return axiosJwt.post(
            `${api}/mapaparceiros/parceria/aditivo/${obj}/ativar`
        );
    },

    AlterarAcordoDeParceriaClausulas(id, data) {
        return axiosJwt.post(`${api}/mapaparceiros/parceria/${id}/alterar/clausulas`, data);
    },

    AlterarAcordoDeParceriaDadosDoContrato(id, data) {
        return axiosJwt.post(`${api}/mapaparceiros/parceria/${id}/alterar/dadoscontrato`, data);
    },

    AlterarAcordoDeParceriaValores(id, data) {
        return axiosJwt.post(`${api}/mapaparceiros/parceria/${id}/alterar/valoresperfis`, data);
    },

    ConcluirAditivo(id, data) {
        return axiosJwt.post(`${api}/mapaparceiros/parceria/${id}/concluiraditivo`, data);
    },
    
    BuscarAssinantes(parceiroId) {
        return axiosJwt.get(`${api}/mapaparceiros/assinaturaacordo/${parceiroId}/assinantes`);
    },
    EnviarAcordoParaAssinatura(id, idAditivo) {
        return axiosJwt.post(`${api}/mapaparceiros/assinaturaacordo/assinar`, { idAcordo: id, idAcordoAditivo: idAditivo });
    },
    BuscarStatusAssinaturas(parceiroId) {
        return axiosJwt.get(`${api}/mapaparceiros/assinaturaacordo/${parceiroId}/assinaturas`);
    },
    RechecarStatusAssinaturas(parceiroId) {
        return axiosJwt.patch(`${api}/mapaparceiros/assinaturaacordo/${parceiroId}/checar-status-assinaturas`);
    },
    BaixarDocumentoAssinadoOuDocumentoOriginal(id) {
        return axiosJwt.get(`${api}/mapaparceiros/assinaturaacordo/${id}/baixar-documento-assinado`, { responseType: 'blob' })
            .then(response => {
                return response.data
            })
    },
    AlterarAssinatura(id, novoEmail) {
        return axiosJwt.post(`${api}/mapaparceiros/assinaturaacordo/alterar-assinatura`, { id, email: novoEmail });
    },
    obterTestemunhas(){
        return axiosJwt.get(`${api}/mapaparceiros/testemunhas`);
    },
    obterTestemunha(id){
        return axiosJwt.get(`${api}/mapaparceiros/testemunhas/${id}`);
    },
    inserirTestemunha(testemunha){
        return axiosJwt.post(`${api}/mapaparceiros/testemunhas`, testemunha);
    },
    deletarTestemunha(id){
        return axiosJwt.delete(`${api}/mapaparceiros/testemunhas/${id}`);
    },
    alterarTestemunha(testemunha){
        return axiosJwt.put(`${api}/mapaparceiros/testemunhas/${testemunha.id}`, testemunha);
    },
    inserirTestemunhaParceria(obj) {
        return axiosJwt.post(`${api}/mapaparceiros/parceria/inserirtestemunha`, obj);
    },
    deletarTestemunhaParceria(obj) {
        return axiosJwt.post(`${api}/mapaparceiros/parceria/deletartestemunha`, obj);
    },
};