<template>
    <Button label="Adicionar" icon="pi pi-plus" class="ml-2" @click="showDialog()" v-if="operacao == '1'" />
    <font-awesome-icon icon="pen" @click="showDialog()" size="lg" class="icon-button p-text-info ml-2" title="Editar" v-if="operacao == '2'" />

    <Dialog v-model:visible="display" no-close-on-backdrop :style="{ width: '45vw' }" :maximizable="false" :closable="false">
        <template #header>
            <h3>{{ operacaoTexto }} - Testemunha: {{ nome }}</h3>
        </template>

        <div class="formgrid grid p-fluid">
            <div class="field col-9">
                <label class="required">Nome</label>
                <InputText type="text" v-model="nome" />
            </div>
            <div class="field col-3 field-checkbox">
                <Checkbox :binary="true" v-model="ckAssinaAcordo" />
                <label for="accept">Assina Acordo Parceria?</label>
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col-9">
                <label class="required">E-mail Pessoal</label>
                <InputText type="text" v-model="email" />
            </div>
        </div>
        <template #footer>
            <Button label="Salvar" @click="salvar()" :disabled="v$.$invalid" />
            <Button label="Fechar" autofocus @click="closeDialog()" />
        </template>
    </Dialog>
</template>

<script>
import Services from '../../service.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    props: {
        testemunhaObjeto: {
            type: Object,
        },
        operacao: {
            type: Number,
        },
    },

    setup() {
        return { v$: useVuelidate() };
    },

    validations() {
        return {
            nome: { required },
            email: { required },
        };
    },

    data() {
        return {
            display: false,
            nome: null,
            ckAssinaAcordo: true,
            operacaoTexto: 'Cadastro',
            email: null,
            testemunha: null,
        };
    },

    methods: {
        showDialog() {
            this.display = true;
            this.nome = null;
            this.email = null;
            this.ckAssinaAcordo = true;
            if (this.operacao == 2) {
                this.operacaoTexto = 'Edição';
                this.obterTestemunha();
            }
        },

        limparCampos() {
            this.nome = null;
            this.email = null;
            this.ckAssinaAcordo = false;
        },

        closeDialog() {
            this.display = false;
            this.limparCampos();
        },

        obterTestemunha() {
            this.$store.dispatch('addRequest');
            Services.obterTestemunha(this.testemunhaObjeto.id).then((response) => {
                if (response && response.success) {
                    this.testemunha = response.data;
                    this.nome = this.testemunha.nome;
                    this.email = this.testemunha.email;
                    if (this.testemunha.assinaAcordoParceria == 1) {
                        this.ckAssinaAcordo = true;
                    }
                } else {
                    this.testemunha = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        salvar() {
            let testemunhaDto = {
                nome: this.nome,
                email: this.email,
                assinaAcordoParceria: this.ckAssinaAcordo ? 1 : 0,
            };
            if (this.operacao == 1) {
                this.$store.dispatch('addRequest');
                Services.inserirTestemunha(testemunhaDto).then((response) => {
                    if (response && response.success) {
                        this.limparCampos();
                        this.$emit('atualizarLista');
                    }
                    this.$store.dispatch('removeRequest');
                });
            } else {
                testemunhaDto.id = this.testemunha.id;
                this.$store.dispatch('addRequest');
                Services.alterarTestemunha(testemunhaDto).then((response) => {
                    if (response && response.success) {
                        this.$emit('atualizarLista');
                    }
                    this.$store.dispatch('removeRequest');
                });
            }
            this.closeDialog();
        },
    },
};
</script>