<template>
    <font-awesome-icon icon="info-circle" @click="showDialog()" size="lg" class="icon-button p-text-primary" title="Detalhar" />
    <Dialog v-model:visible="display" no-close-on-backdrop :style="{ width: '70vw' }" :maximizable="true">
        <template #header>
            <h3>Detalhes - Testemunha</h3>
        </template>
        <div class="grid">
            <div class="col-6">
                <div class="mb-4">
                    <detalhe titulo="Identificador: " :size="sizeForm">{{ testemunha.id }}</detalhe>
                    <detalhe titulo="Nome: " :size="sizeForm">{{ testemunha.nome }}</detalhe>
                    <detalhe titulo="Email: " :size="sizeForm">{{ testemunha.email }}</detalhe>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Fechar" autofocus @click="closeDialog()" />
        </template>
    </Dialog>
</template>

<script>
// import Services from '../../service.js';

export default {
    props: {
        testemunhaObjeto: {
            type: Object,
        },
    },

    data() {
        return {
            display: false,
            testemunha: null,
        };
    },

    methods: {
        showDialog() {
            this.display = true;
        },

        closeDialog() {
            this.display = false;
        },

        // obterTestemunha() {
        //     this.$store.dispatch('addRequest');
        //     Services.obterTestemunha(this.testemunha.id).then((response) => {
        //         if (response && response.success) {
        //             this.testemunha = response.data;
        //         } else {
        //             this.testemunha = null;
        //         }
        //         this.$store.dispatch('removeRequest');
        //     });
        // },
    },

    computed: {
        sizeForm() {
            return '200';
        },
    },
    mounted() {
        this.testemunha = this.testemunhaObjeto
    }
};
</script>