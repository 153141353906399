<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div class="flex">
                    <h5>Parça - Listagem das Testemunhas</h5>
                </div>
                <tabela :data="lista" :operacao="1">
                    <template #botoes>
                        <CadTestemunha 
                            @atualizarLista="obterTestemunhas()" 
                            :operacao="1" 
                            v-if="$temAcessoView('MAPAPARC-TES-01') && !externo">
                        </CadTestemunha>
                    </template>

                    <template #conteudo>
                        <Column header="Operações" headerStyle="width: 8em">
                            <template #body="slotProps">
                                <Detalhes 
                                    :testemunhaObjeto="slotProps.data">
                                </Detalhes>
                                <CadTestemunha 
                                    @atualizarLista="obterTestemunhas()" 
                                    :operacao="2" 
                                    :testemunhaObjeto="slotProps.data"
                                    v-if="$temAcessoView('MAPAPARC-TES-02') && !externo">
                                </CadTestemunha>
                                <font-awesome-icon 
                                    icon="trash-alt" 
                                    @click="confirmarExclusao(slotProps.data.id)" 
                                    size="lg" 
                                    class="icon-button ml-2" 
                                    title="Deletar" 
                                    style="color: red" 
                                    v-if="$temAcessoView('MAPAPARC-TES-03') && !externo"/>
                            </template>
                        </Column>
                        <Column field="nome" header="Nome" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.nome }}
                            </template>
                        </Column>
                        <Column field="email" header="E-mail" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.email }}
                            </template>
                        </Column>
                    </template>
                </tabela>
            </div>
        </div>
    </div>
<router-view></router-view>
</template>

<script>
import Services from '../../service.js';
import Detalhes from '../detalhes/DetalhesTestemunha.vue';
import CadTestemunha from '../cadastro/CadTestemunha.vue';

export default {
    components: {
        Detalhes,
        CadTestemunha,
    },

    data() {
        return {
            lista: [],
            externo: false,
        };
    },

    methods: {
        confirmarExclusao(id) {
            this.$swal({
                title: 'Excluir',
                html:'Deseja Excluir a Testemunha?',
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.value) {
                    this.deletar(id)
                }
            })
        },

        obterTestemunhas() {
            this.$store.dispatch('addRequest');
            Services.obterTestemunhas().then((response) => {
                if (response && response.success) {
                    this.lista = response.data;
                } else {
                    this.lista = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        deletar(id) {
            this.$store.dispatch('addRequest');
            Services.deletarTestemunha(id).then((response) => {
                if (response && response.success) {
                    this.obterTestemunhas();
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterTestemunhas();
    },
};
</script>